import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Banner from "../components/Banner/Banner"

const Artroscopia = () => {
  const data = useStaticQuery(graphql`
    query {
      rodilla: file(relativePath: { eq: "rodilla.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hombro: file(relativePath: { eq: "hombro.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tobillo: file(relativePath: { eq: "tobillo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      muneca: file(relativePath: { eq: "muneca.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContextProvider>
      <Layout>
        <SEO title="Cirugía Artroscópica" />
        <div className={classes.root}>
          <Banner content="Cirugía Artroscópica" />
          <h3 className={classes.title}>Artroscopía</h3>
          <p className={classes.content}>
            Es una <span className={classes.bold}>cirugía ambulatoria</span> que
            consiste en introducir un sistema óptico e instrumental muy
            especifico a traves de dos pequeñas incisiones en articulaciones
            como la rodilla, hombros, tobillo, muñeca, entre otras, para
            solucionar lesiones ligamentarias, meniscales, de cartilago
            articular y demás, permitiendo una{" "}
            <span className={classes.bold}>pronta recuperación</span> y vuelta
            al deporte y/o a su actividad laboral habitual.
          </p>
          <div className={classes.imageContainer}>
            <Img fluid={data.hombro.childImageSharp.fluid} />
            <Img fluid={data.muneca.childImageSharp.fluid} />
            <Img fluid={data.rodilla.childImageSharp.fluid} />
            <Img fluid={data.tobillo.childImageSharp.fluid} />
          </div>
        </div>
      </Layout>
    </ContextProvider>
  )
}

export default Artroscopia
